import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

//Context
import { ProjectProvider } from './contexts/data/ProjectContext';

// Components
import Sidebar from './components/Sidebar';

// Pages
import Homepage from './components/pages/Homepage';
import ProjectPage from './components/pages/ProjectPage';

// Styles
import GlobalStyle from './components/styles/GlobalStyles';
import * as HomeStyles from './components/styles/HomeStyles';

// Theming
import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from './components/styles/Themes';



const App = () => {

  //light/dark mode
  const [isLightMode, setLightMode] = useState(false);
  const toggleDarkMode = (checked) => {
    setLightMode(checked);
  };

  //persist state of isLightMode:

  //ensures this persists on page refresh, rerender etc.
  useEffect(() => {
    //localStorage only stores strings
    const themePref = JSON.parse(localStorage.getItem("theme-pref")) || false;
    toggleDarkMode(themePref);
  }, []);

  useEffect(() => {
    localStorage.setItem("theme-pref", JSON.stringify(isLightMode))
  }, [isLightMode]);

 

  return <>
    <ProjectProvider>
      <ThemeProvider theme={isLightMode ? lightTheme : darkTheme}>
        <GlobalStyle />
          <Router>
            <Route path="/" render={() => { window.scroll({top: 0, left: 0}); return null; }} />

            <HomeStyles.ContentWrapper>

              <HomeStyles.SidebarWrapper>
                <Sidebar toggleCallback={toggleDarkMode} toggleChecked={isLightMode} moonColor={"#97aca5"} sunColor={'#372920'} />
              </HomeStyles.SidebarWrapper>

              <div>
                <Switch>
                  <Route exact path="/">
                    <Homepage />
                  </Route>

                  <Route path="/project/:projectURL">
                    <ProjectPage />
                  </Route>
                </Switch>
              </div>

            </HomeStyles.ContentWrapper>

          </Router>
      </ThemeProvider>
    </ProjectProvider>
  </>
};

export default App;
