import styled, {keyframes} from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export const Wrapper = styled.div`
  display: flex;
  flex-direction:row;

  @media (max-width:1000px){
    flex-direction: column-reverse;
    padding:0 25px;
  }
`;


export const ImageColumn = styled.div`
  width:50%;
  margin-top:40px;
  padding:0 30px;
  @media (max-width:1000px){
    width:100%;
    padding:0;
  }
`;


export const Images = styled.img`
  margin-bottom:1.7rem;
  width:100%;
  border-radius: 10px;
`;

export const DescriptionWrapper = styled.div`
  position: sticky;
  align-self: flex-start;
  width: 50%;
  top:15%;
  padding-right:30px;
  @media (max-width:1000px){
    position:unset;
    width:90%;
    padding:35px 0 0;
  }
`;

//scroll prompt 
export const ScrollPromptWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const pulse = keyframes`
  from {
    transform: scale(0.95);
  }

  to {
    transform: scale(1.1);
  }
`;

export const ScrollPrompt = styled(FontAwesomeIcon)`
  z-index: 10;
  position:fixed;
  bottom:10%;
  transition:0.6s ease;
  color: #fff;
  animation: ${pulse} 0.7s ease-in alternate infinite;

  ${props => props.scrollHeight > 80 ? 'opacity:0%' : 'opacity:90%'}
`;

