import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import * as api from '../../services/api';

//icons
import { faArrowCircleDown } from '@fortawesome/free-solid-svg-icons';

//context
import { useProject } from '../../contexts/data/ProjectContext';

//styles
import * as ProjectStyles from '../styles/ProjectStyles';

const ReactMarkdown = require('react-markdown');


const ProjectPage = () => {
  //handle visibility of scroll prompt
  const [scrollHeight, setScrollHeight] = useState(0);

  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollHeight(position);
  };
  
  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  
  //get data for correct page from strapi
  const project = useProject();
  const [data, setData] = useState(undefined);

  const getData = async (id) => {
    let response = await api.get(`/projects?projectURL=${id}`);
    if (response.length === 0) {
      // not found, 404? need to make 404 page.
      setData(undefined);
    } 
    else {
      setData(response[0]);
    }
  }

  const routeMatch = useRouteMatch();
  const [orderedImages, setOrderedImages] = useState([]);

  useEffect(() => {
    let id = routeMatch.params.projectURL;
    getData(id);
    project.getData();
  }, []);

  useEffect(() => {
    setData(undefined);
    let id = routeMatch.params.projectURL;
    getData(id);
  }, [routeMatch.params.projectURL]);

  //sort images alphanumerically
  useEffect(() => {
    let tempData = data?.Images || [];
    tempData.sort((a, b) => (a.name > b.name) ? 1 : -1);
    setOrderedImages(tempData);
  }, [data]);

 
  return <>
    <ProjectStyles.Wrapper>


      <ProjectStyles.ImageColumn>
        <ProjectStyles.ScrollPromptWrapper >
          <ProjectStyles.ScrollPrompt icon={faArrowCircleDown} size='2x' scrollHeight={scrollHeight} ></ProjectStyles.ScrollPrompt>
        </ProjectStyles.ScrollPromptWrapper>

        {orderedImages?.map((image, index) => <>
          <ProjectStyles.Images src={api.serverPath + image?.url}></ProjectStyles.Images>
          {/* {image?.formats ? api.serverPath + image?.formats?.large?.url : api.serverPath + image?.url} if you want to specify image size and also support gifs, but at this occasion we want original size */}
        </>)}
      </ProjectStyles.ImageColumn>


      <ProjectStyles.DescriptionWrapper>
        <h1>{data?.Title}</h1>
        <h2>{data?.Category}</h2> 
        <div><ReactMarkdown source={data?.Description}/></div>
      </ProjectStyles.DescriptionWrapper>


    </ProjectStyles.Wrapper>
  </>
};

export default ProjectPage;