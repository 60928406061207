import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as api from '../services/api';

//Styles
import * as HomeStyles from './styles/HomeStyles';

//Context
import { useProject } from '../contexts/data/ProjectContext';

const Project = ({Name, Image, ProjectURL, ...props}) => {
  return <>
    <HomeStyles.ImageWrapper>

      <HomeStyles.Image src={Image}></HomeStyles.Image>

      <Link to={{pathname: '/project/'+ProjectURL}} style={{textDecoration: "none"}}>
        <HomeStyles.Overlay>
          <HomeStyles.OverlayTitle>
            {Name}
          </HomeStyles.OverlayTitle>
        </HomeStyles.Overlay>
      </Link>

    </HomeStyles.ImageWrapper>
  </>
};

const Section = ({SectionTitle, ProjectList, ...props }) => {
  const project = useProject();

  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState(project.data);


  useEffect(() => {
    // Get data on page load (from context)
    project.getData();
  }, []);

  //sets data when data changes
  useEffect(() => {
    setData(project.data);
  }, [project.data]);


  //orders data via manually set UID on strapi, as strapi id's cannot be changed :(
  useEffect(() => {
    let newDisplayData = [];
    
    //pushing relevant projects to new array
    data.map((item) => {
      if (item.Category.toUpperCase() === SectionTitle) {
        newDisplayData.push(item);
      }
    });
    
    newDisplayData.sort((a, b) => (a.newID > b.newID) ? 1 : -1);
    setFilteredData(newDisplayData);

  }, [data]);


  return <>
    <HomeStyles.Title>{SectionTitle}</HomeStyles.Title>
    <br/>
    
    <HomeStyles.ColumnContainer>
      {filteredData?.map((project, index) => <>
          <Project Image={api.serverPath + project?.CoverImage?.formats.medium?.url} Name={project.Title} ProjectURL={project.projectURL}/>
        </>
      )}
    </HomeStyles.ColumnContainer>
  </>
};

export default Section;