import styled from 'styled-components';
import { DarkModeSwitch } from 'react-toggle-dark-mode';
import { NavHashLink } from 'react-router-hash-link';


export const DarkToggle = styled(DarkModeSwitch)`
  margin-top:25px;
  @media (max-width:500px) {
    margin-left:30px;
    width:30px;
  }
`;


export const SidebarWrapper = styled.div`
  background-color: ${({theme}) => theme.sidebarColour};
  position:sticky;
  top:0;
  left:0;
  height:100vh;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  align-content:left;

  @media (max-width:500px) {
    position: absolute;
    width:100%;
    height:300px;
    padding:unset;
    flex-direction:row;
    align-content:center;
  }
`;


export const Portrait = styled.img`
  width:70%;
  max-width: 160px;
  padding: 20% 0 5% 0;
  margin-left:15%;

  @media (max-width:500px) {
    text-align:center;
    padding:80px 20px 0 0;
    width:80%;
    margin-left:-20px;
    min-width:115px;
  }
`;


export const BioWrapper = styled.div`
  align-self:center;
`;


export const AboutText = styled.p`
  max-width: 260px;

  @media (max-width:420px) {
    font-size:0.85em;
    max-width:270px;
  }

  @media (max-width:500px) {
    margin-right:20px;
  }
`;


export const MenuWrapper = styled.div`
  margin: 1em 0 1em 0;
  line-height:1.2em;
`;


export const MenuItem = styled(NavHashLink)`
  .selected{
    color: ${({theme}) => theme.headingColour};
  }

  &:hover {
    color: ${({theme}) => theme.headingColour};
  }

  @media (max-width:500px) {
    color: ${({theme}) => theme.sidebarColour};
    line-height:1.5em;
    font-size:1.2em;
  }
`;


export const ContactWrapper = styled.div`
  display: flex;
  margin-top: 2em;
  color:${({theme}) => theme.headingColour};

  @media (max-width:500px) {
    margin-top:15px;
    font-size:0.92em;
  }
`;


export const ContactIcon = styled.a`
  margin: 0 5px 5px 5px;
  color:${({theme}) => theme.headingColour};
  transition:0.1s ease;
  &:hover {
    transform: scale(1.2);
  }
`;



//mobile specific
export const MobileMenuHider = styled.div`
  @media (max-width:500px) {
    display:none;
  }
`;


export const MobileMenuWrapper = styled.div`
  display:none;

  @media (max-width:500px) {
    display:block;
    position:fixed;
    top:20px;
    right:25px;
    z-index:42;
    background-color:${({theme}) => theme.fontColour};
    color: ${({theme}) => theme.sidebarColour};
    padding:3px 4px;
    border-radius:2px;
  }
`;


export const DropdownMenu = styled.div`
  display:none;

  @media (max-width:500px) {
    transition: all 0.5s;
    ${props => props.menuOpen ? 'display:block' : ''};
    background-color:${({theme}) => theme.fontColour};
    color: ${({theme}) => theme.sidebarColour};
    padding:5px 25px;
    position:fixed;
    top:50px;
    right:25px;
    z-index:45;
    border-radius:5px;
  }
`;