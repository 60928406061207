import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const ContentWrapper = styled.div`
  display:grid;
  grid-template-columns: minmax(250px, 22%) 1fr;

  @media (max-width: 500px) {
    grid-template-rows: 300px 1fr;
    grid-template-columns:unset;
  }
`;


export const SidebarWrapper = styled.div`
  background-color: ${({theme}) => theme.sidebarColour};
  width:100%;

  @media (max-width:500px) {
    height:300px;
  }
`;

//content/body wrapper
export const Wrapper = styled.div`
  padding: 0 30px;
  display:flex;
  flex-direction:column;
`;


export const Title = styled.h1`
  padding-top:30px;
`;


export const Image = styled(LazyLoadImage)`
  display: block;
  width:100%;
`;


export const Overlay = styled.div`
  position:absolute;
  transition: all 0.3s ease;
  background-color: black;
  border-radius: 5px;
  cursor: pointer;
  opacity:0;
  top:0;
  left:0;
  width:100%;
  height:0;
  overflow:hidden;
`;


export const ImageWrapper = styled.div`
  margin-bottom:20px;
  margin-left:20px;
  position:relative;
  overflow: hidden;
  break-inside: avoid;
  &:hover{
    & ${Overlay}{
      opacity:80%;
      height:100%;
    }
  }
`;


export const OverlayTitle = styled.div`
  letter-spacing: 0.2em;
  font-size: 1.7em;
  position: absolute;
  bottom: 10%;
  color:white;
  padding: 0 10%;
  line-height: normal;

`;


export const ColumnContainer = styled.div`
  width:100%;
  /* column-count:3;
  column-gap:20px;
  margin-bottom: 30px; */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @media (max-width:1000px){
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width:800px){
    grid-template-columns: 1fr;
  }
`;