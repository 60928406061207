import React, { useState, useEffect, useContext } from 'react';
import * as api from '../../services/api';

const ProjectContext = React.createContext({});

export const useProject = () => useContext(ProjectContext);

export const ProjectConsumer = ProjectContext.Consumer;

export const ProjectProvider = ({ children }) => {
  const [ data, setData ] = useState([]);

  const getData = async (force = false) => {
    // Only update if object empty or forced refresh
    if (
      (data.length === 0 || force) // for arrays (other line for object)
      || force
    ) {
      let response = await api.get('/projects');
      console.log(response);
      setData(response);
    }
  }

  return(
    <ProjectContext.Provider
      value={{
        data,
        getData
      }}
    >
      {children}
    </ProjectContext.Provider>
  )
}

export default ProjectContext;