import React, {useEffect} from 'react';
import Section from '../Section';
import * as HomeStyles from '../styles/HomeStyles';



const Homepage = () => {

  //scrolling to sections when coming from other pages
  useEffect(() => {

    const {hash} = window.location;
    if (hash) {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        //pause necessary for components to render... (lazy loading may fix at some point)
        setTimeout(() => {
          element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }, 400);
      }
    }

  }, []);

  return <>
      <HomeStyles.Wrapper>
        <div id='publicity'> <Section SectionTitle={'THEATRE PUBLICITY'}/></div>
        <div id='illustrations'> <Section SectionTitle={'ILLUSTRATIONS'}/></div>
        <div id='branding'> <Section SectionTitle={'LOGOS AND BRANDING'}/> </div>
        <div id='web'><Section SectionTitle={'WEB DEVELOPMENT'}/></div>
      </HomeStyles.Wrapper>
  </>
}

export default Homepage;