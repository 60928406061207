import { createGlobalStyle } from 'styled-components';


const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: 'Inconsolata', sans-serif;
    background-color: ${({theme}) => theme.bgColour};
    color: ${({theme}) => theme.fontColour};
    font-weight:100;
    overflow-x: hidden;
    font-size:0.95em;
  }

  h1{
    color: ${({theme}) => theme.headingColour};
    letter-spacing: 0.2em;
    font-size: 1.50em;
    margin:0;
  }

  h2{
    color: var(--font-color);
    font-size: 1.2em;
    font-style: italic;
  }

  a{
    color: ${({theme}) => theme.fontColour}
  }

  img{
    border-radius: 5px;
    filter: brightness(${({theme}) => theme.imgBrightness});
  }

`;

export default GlobalStyle;