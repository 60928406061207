

export const darkTheme = {
  fontColour: "#97aca5",
  bgColour: "#202525",
  headingColour: "#d1afb2",
  sidebarColour: "#323d3f",
  imgBrightness: "86%"
};

export const lightTheme = {
  fontColour: "#372920",
  bgColour: "#fffaf1",
  headingColour: "#ab6e37",
  sidebarColour: "#f3dcb8",
  imgBrightness: "100%"
};


