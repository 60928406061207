import React, { useEffect, useState } from 'react';
import { HashLink } from 'react-router-hash-link';
import * as api from '../services/api';

//Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub, faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faBars } from '@fortawesome/free-solid-svg-icons';

// Styles
import * as SidebarStyles from './styles/SidebarStyles';


const Menu = () => {

  return <>
    <SidebarStyles.MenuWrapper>
      <SidebarStyles.MenuItem smooth to="/#publicity" activeClassName="selected">PUBLICITY</SidebarStyles.MenuItem>
      <br/> 
      <SidebarStyles.MenuItem smooth to="/#illustrations" activeClassName="selected" >ILLUSTRATIONS</SidebarStyles.MenuItem>
      <br/>
      <SidebarStyles.MenuItem smooth to="/#branding" activeClassName="selected">LOGOS AND BRANDING</SidebarStyles.MenuItem>
      <br/>
      <SidebarStyles.MenuItem smooth to="/#web" activeClassName="selected">WEB DEVELOPMENT</SidebarStyles.MenuItem>
      <br/>
    </SidebarStyles.MenuWrapper>
  </>
};


const MobileMenu = () => {

  const [menuOpen, setMenuOpen] = useState(false);

  return <>
    <SidebarStyles.MobileMenuWrapper onClick={() => setMenuOpen(!menuOpen)}> 
      {/* need to pass in as a function otherwise will run on render as well */}
      <FontAwesomeIcon icon={ faBars } size='lg' style={{zIndex:43}}/>
    </SidebarStyles.MobileMenuWrapper>

    <SidebarStyles.DropdownMenu menuOpen={menuOpen}>
      <Menu />
    </SidebarStyles.DropdownMenu>
  </>
};


const Contact = () => {

  return <>
    <SidebarStyles.ContactWrapper>CONTACT |

      <SidebarStyles.ContactIcon href="mailto:eys23@cam.ac.uk">
        <FontAwesomeIcon icon={ faEnvelope } />
      </SidebarStyles.ContactIcon>
      <SidebarStyles.ContactIcon>
        <FontAwesomeIcon icon={ faLinkedin } />
      </SidebarStyles.ContactIcon>
      <SidebarStyles.ContactIcon>
        <FontAwesomeIcon icon={ faGithub } />
      </SidebarStyles.ContactIcon>

    </SidebarStyles.ContactWrapper>
  </>
};


const Sidebar = ({toggleCallback, toggleChecked, bio, ...props}) => {

  //get bio from strapi api
  const [data, setData] = useState(undefined);

  const getData = async () => {
    let response = await api.get(`/sidebar`);
    setData(response);
  };

  useEffect(() => {
    getData();
  }, []);

  return <>
    <SidebarStyles.SidebarWrapper>


      <SidebarStyles.DarkToggle checked={toggleChecked} onChange={toggleCallback} moonColor={"white"} sunColor={"whit"}/>


      <HashLink smooth to="/">
        <SidebarStyles.Portrait src='/assets/portrait.png'></SidebarStyles.Portrait>
      </HashLink>


      <SidebarStyles.BioWrapper>

        <HashLink smooth to="/" style={{textDecoration:'none'}}> 
          <h1>EMILY SHEN</h1>
        </HashLink>

        <p>2nd Year Medicine | Cambridge</p>

        <SidebarStyles.AboutText>
          {data?.Bio}
        </SidebarStyles.AboutText>

        <SidebarStyles.MobileMenuHider>
          <Menu />
        </SidebarStyles.MobileMenuHider>

        <Contact />

      </SidebarStyles.BioWrapper>

      <MobileMenu />
      

    </SidebarStyles.SidebarWrapper>
  
  </>
};

export default Sidebar;