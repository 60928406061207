export const serverPath = 'https://admin.emilyshen.co.uk';


export const get = async (path) => {
  let response = await fetch(`${serverPath}${path}`);
  let json = await response.json();
  return json;
};


